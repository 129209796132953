import React from "react";
import { ITheme, IDefinitions, buildClasses } from "@nodeme/jss-react";
import Headline from "./Headline";
import Text from "./Text";
import Button from "@nodeme/forms-react/lib/Components/Button";
import { Text as TextF } from "@nodeme/forms-react";
import { Grid } from "@nodeme/grid-react";

interface IComment {
  previouse: () => void;
  next: () => void;
  onChange: (value?: string) => void;
  value: string | undefined;
  error: string | undefined;
}

const styles = (theme: ITheme) => (definitions: IDefinitions) => ({
  alignRight: {
    textAlign: "right",
  },
});

const Comment: React.FC<IComment> = (props) => {
  const classes = buildClasses(styles, props);
  return (
    <div>
      <Headline>KOMMENTAR</Headline>
      <Text>Ein richtig schmalziger Text der die Tränendrüse gut drückt.</Text>
      <div>
        <TextF
          inline
          label="Kommentar"
          rows={5}
          onChange={props.onChange}
          value={props.value}
          error={props.error}
        />
      </div>
      <Grid spacing={8} root={{ top: 16, left: 0, right: 0 }}>
        <Grid item xs={5}>
          <Button width="120px" onClick={() => props.previouse()}>
            Zurück
          </Button>
        </Grid>
        <Grid item xs={5}>
          <div className={classes.alignRight}>
            <Button
              /* disabled={!props.value} */
              primary
              width="120px"
              onClick={() => props.next()}
            >
              Abschicken
            </Button>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default Comment;
