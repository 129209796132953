import React from "react";
import { ITheme, IDefinitions, buildClasses } from "@nodeme/jss-react";

interface ISubheading {
  children: any;
}

const styles = (theme: ITheme) => (definitions: IDefinitions) => ({
  txt: {
    fontSize: "1rem",
    fontWeight: "600",
    marginBottom: "0px",
  },
});

const Block: React.FC<ISubheading> = (props) => {
  const classes = buildClasses(styles, props);
  return <h2 className={classes.txt}>{props.children}</h2>;
};

export default Block;
