import React from "react";
import { ITheme, IDefinitions, buildClasses } from "@nodeme/jss-react";
import Headline from "./Headline";
import Subheading from "./Subheading";
import Text from "./Text";
import Button from "@nodeme/forms-react/lib/Components/Button";

interface IInfo {
  next: () => void;
}

const styles = (theme: ITheme) => (definitions: IDefinitions) => ({
  alignRight: {
    textAlign: "right",
  },
});

const Info: React.FC<IInfo> = (props) => {
  const classes = buildClasses(styles, props);
  return (
    <div>
      <Headline>INFO</Headline>
      <Subheading>Was ist das hier?</Subheading>
      <Text>
        Hier habt ihr die Möglichkeit eine Art digitalen Gästebuch Eintrag zu
        erstellen. Diese werden dann an Marlies und Bendi weiter geschickt. Ziel
        ist es sie zum Lachen / Weinen oder am besten beides zu bringen.
      </Text>
      <Subheading>Was passiert mit den Daten?</Subheading>
      <Text>
        Die Daten werden bei Amazon (AWS) zwischen gespeichert und sind solange
        öffentlich zugänglich bis alle Beiträge an Marlies und Bendi übergeben
        worden sind. Danach werden Sie gelöscht.
      </Text>
      <div className={classes.alignRight}>
        <Button primary width="120px" onClick={() => props.next()}>
          Akzeptieren
        </Button>
      </div>
    </div>
  );
};

export default Info;
