import React from "react";
import { ITheme, IDefinitions, buildClasses } from "@nodeme/jss-react";

interface IHeadline {
  children: any;
}

const styles = (theme: ITheme) => (definitions: IDefinitions) => ({
  txt: {
    color: theme.palette.get("primary"),
    fontSize: "1.1rem",
    fontWeight: "600",
    letterSpacing: "1px",
  },
});

const Block: React.FC<IHeadline> = (props) => {
  const classes = buildClasses(styles, props);
  return <h1 className={classes.txt}>{props.children}</h1>;
};

export default Block;
