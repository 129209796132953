import React from "react";
import { ITheme, IDefinitions, buildClasses } from "@nodeme/jss-react";
import Headline from "./Headline";
import Text from "./Text";
import Button from "@nodeme/forms-react/lib/Components/Button";
import UploadArea from "@nodeme/forms-react/lib/Components/UploadArea";
import { Grid } from "@nodeme/grid-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faUpload } from "@fortawesome/pro-light-svg-icons";

interface IPhoto {
  previouse: () => void;
  next: () => void;
  onChange: (value?: string) => void;
  value: string | undefined;
  error: string | undefined;
}

const styles = (theme: ITheme) => (definitions: IDefinitions) => ({
  alignRight: {
    textAlign: "right",
  },
  upl: {
    textAlign: "center",
    paddingTop: "35px",
  },
  uplTxt: {
    color: theme.palette.get("grey"),
    display: "block",
    margin: "8px",
  },
  uplIcon: {
    fontSize: "3rem",
  },
  img: {
    maxHeight: "150px",
  },
  imgHolderHoler: {
    textAlign: "center",
  },
  imgHolder: {
    position: "relative",
    display: "inline-block",
  },
  deleteBtnHolder: {
    position: "absolute",
    right: 2,
    top: 2,
    boxShadow: "0px 0px 3px 1px #000",
  },
});

const Info: React.FC<IPhoto> = (props) => {
  const classes = buildClasses(styles, props);
  const data = (props.value || "").split(",")[1];
  return (
    <div>
      <Headline>FOTO</Headline>
      <Text>Bringt sie zum Lachen mit einem crazy Foto.</Text>
      <div>
        {!data && (
          <UploadArea
            height={150}
            inline
            label="Foto"
            onChange={props.onChange}
            value={props.value}
            error={props.error}
          >
            <div className={classes.upl}>
              <FontAwesomeIcon className={classes.uplIcon} icon={faUpload} />
              <br />
              <span className={classes.uplTxt}>
                <b>Foto</b> hochladen
              </span>
            </div>
          </UploadArea>
        )}
        {Boolean(data) && (
          <div className={classes.imgHolderHoler}>
            <div className={classes.imgHolder}>
              <div className={classes.deleteBtnHolder}>
                <Button
                  item
                  danger
                  width="36px"
                  onClick={(e) => {
                    e.preventDefault();
                    props.onChange(undefined);
                  }}
                >
                  <FontAwesomeIcon icon={faTrash} />
                </Button>
              </div>
              <img
                alt="preview"
                className={classes.img}
                src={"data:image/jpeg;base64, " + data}
              />
            </div>
          </div>
        )}
      </div>
      <Grid
        spacing={8}
        root={{ top: props.error ? 24 : 16, left: 0, right: 0 }}
      >
        <Grid item xs={5}>
          <Button width="120px" onClick={() => props.previouse()}>
            Zurück
          </Button>
        </Grid>
        <Grid item xs={5}>
          <div className={classes.alignRight}>
            <Button primary width="120px" onClick={() => props.next()}>
              Weiter
            </Button>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default Info;
