import React, { useState } from "react";
import classNames from "classnames";
import { Container, Grid } from "@nodeme/grid-react";
import { Form } from "@nodeme/forms-react";
import "./assets/styles/app.css";
import { ITheme, IDefinitions, buildClasses } from "@nodeme/jss-react";
import Block from "./components/Block";
import Wizzard from "./components/Wizzard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRingsWedding } from "@fortawesome/pro-duotone-svg-icons";
import {
  faAddressCard,
  faCameraRetro,
  faEdit,
  faInfo,
  faCheck,
} from "@fortawesome/pro-light-svg-icons";
import Info from "./components/Info";
import Contact from "./components/Contact";
import Photo from "./components/Photo";
import Comment from "./components/Comment";
import img from "./assets/imgs/aside.jpg";

const style = (theme: ITheme) => (definitions: IDefinitions) => ({
  main: {
    height: "100vh",
    width: "100%",
  },
  row: {
    height: "calc(80vh + 16px)",
  },
  alignRight: {
    textAlign: "right",
  },
  overlayHolder: {
    position: "relative",
  },
  overlay: {
    textAlign: "center",
    paddingTop: "90px",
    position: "absolute",
    zIndex: 2,
    backgroundColor: "rgba(255,255,255,0.96)",
    fontSize: "20px",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
  },
  pending: {
    color: theme.palette.get("primary"),
    fontSize: "3.5rem",
  },
  ringsHolder: {
    textAlign: "center",
  },
  rings: {
    fontSize: "20rem",
    color: "rgba(62, 62, 62, 0.5)",
  },
  success: {
    color: theme.palette.get("success"),
    fontSize: "3.5rem",
  },
  successTxt: {
    fontSize: "1rem",
    color: "#000",
  },
  link: {
    fontSize: "1rem",
    color: theme.palette.get("primary"),
    textDecoration: "underline",
  },
});

interface IField {
  value?: string | undefined;
  error?: string | undefined;
}

const add = (
  name: string | undefined,
  photo: string | undefined,
  comment: string | undefined
) => {
  const Http = new XMLHttpRequest();
  const url = "https://mub.artceptional.com/comment?q=IFt7fSwgW10sIDBd";
  Http.open("POST", url);
  Http.setRequestHeader("Accept", "HtvpkgZ5LmaNqHEE7V3sf1EfOc5HCZhY3EDJBwqH");
  Http.setRequestHeader("Content-Type", "application/json; charset=utf8");
  Http.setRequestHeader(
    "x-api-key",
    "HtvpkgZ5LmaNqHEE7V3sf1EfOc5HCZhY3EDJBwqH"
  );
  const data = { name, thumb: photo, comment };
  Http.send(JSON.stringify(data));

  return new Promise((resolve, reject) => {
    Http.onreadystatechange = (e) => {
      if (Http.readyState === 4) {
        try {
          const response = JSON.parse(Http.response);

          if (Http.status === 200) resolve({ status: 200, response });
          else reject({ status: Http.status, response });
        } catch (err) {
          reject({ status: 500, response: {} });
        }
      }
    };
  });
};

const byteSize = (str: string) => new Blob([str]).size;

function App() {
  const classes = buildClasses(style);
  const [state, setState] = useState("ok");
  const [page, setPage] = useState(0);
  const [name, setName] = useState<IField>({});
  const [photo, setPhoto] = useState<IField>({});
  const [comment, setComment] = useState<IField>({});

  const send = async () => {
    setState("pending");
    try {
      await add(name.value, photo.value, comment.value);
      setState("success");
    } catch (error) {
      if (error.status === 400 && error.response.name === "ValidationError") {
        const errors = error.response.errors;
        if (errors.comment) setComment({ ...comment, error: errors.comment });
        if (errors.thumb) setPhoto({ ...photo, error: errors.thumb });
        if (errors.name) setName({ ...name, error: errors.name });

        setPage(error.name ? 1 : error.thumb ? 2 : error.comment ? 3 : 1);
      }
      setState("error");
    }
  };

  return (
    <div className="App">
      <main className={classes.main}>
        <Container>
          <Grid
            override={{ row: classes.row }}
            spacing={16}
            justify="center"
            alignItems="center"
          >
            <Grid item sm={10} md="rest" lg={"506px"}>
              <img src={img} style={{ maxWidth: "100%" }} alt="aside" />
            </Grid>
            <Grid item md={"350px"}>
              <Block>
                <div className={classes.overlayHolder}>
                  {state === "pending" && (
                    <div
                      className={classNames([classes.overlay, classes.pending])}
                    >
                      <FontAwesomeIcon icon={faRingsWedding} spin />
                    </div>
                  )}
                  {state === "success" && (
                    <div
                      className={classNames([classes.overlay, classes.success])}
                    >
                      <FontAwesomeIcon icon={faCheck} />
                      <br />
                      <div style={{ lineHeight: "1rem" }}>
                        <span className={classes.successTxt}>
                          Danke dein Beitrag wurde gespeichert.
                        </span>
                        <br />
                        <a className={classes.link} href="/">
                          Nochmal
                        </a>
                      </div>
                    </div>
                  )}
                  <Form
                    onSubmit={(e) => {
                      e.preventDefault();
                    }}
                  >
                    <Wizzard
                      icons={[
                        <FontAwesomeIcon icon={faInfo} />,
                        <FontAwesomeIcon icon={faAddressCard} />,
                        <FontAwesomeIcon icon={faCameraRetro} />,
                        <FontAwesomeIcon icon={faEdit} />,
                      ]}
                      show={page}
                    >
                      <Info
                        next={() => {
                          setPage(1);
                        }}
                      />
                      <Contact
                        previouse={() => setPage(0)}
                        next={() => {
                          setPage(2);
                        }}
                        onChange={(value) =>
                          setName({ ...name, value, error: "" })
                        }
                        value={name.value}
                        error={name.error}
                      />
                      <Photo
                        previouse={() => setPage(1)}
                        next={() => {
                          setPage(3);
                        }}
                        onChange={(value) => {
                          if (byteSize(value || "") < 5500000)
                            setPhoto({ ...photo, value, error: "" });
                          else
                            setPhoto({
                              ...photo,
                              error: "Bild muss kleiner 4MB sein.",
                            });
                        }}
                        value={photo.value}
                        error={photo.error}
                      />
                      <Comment
                        previouse={() => setPage(2)}
                        next={() => {
                          send();
                        }}
                        onChange={(value) =>
                          setComment({ ...comment, value, error: "" })
                        }
                        value={comment.value}
                        error={comment.error}
                      />
                    </Wizzard>
                  </Form>
                </div>
              </Block>
            </Grid>
          </Grid>
        </Container>
      </main>
    </div>
  );
}

export default App;
