import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import NodeMeJss from "@nodeme/jss-react";

NodeMeJss.init({
  palette: {
    primary: "#246cb5",
    secondary: "#2c689c",
    tertiary: "#333333",
    danger: "#b10711",
    success: "#559554",
    warning: "#e8db05",
    default: "#d9d9d9",
    body: "#ececec",
    grey: "#7d7d7d",
    black: "#3e3e3e",
    lightGrey: "#ececec",
    background: "#ececec",
    formBackground: "rgba(224, 224, 224, 0.6)",
    formBackgroundActive: "rgba(224, 224, 224, 1)",
  },
});

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
