import React from "react";
import classNames from "classnames";
import { ITheme, IDefinitions, buildClasses } from "@nodeme/jss-react";
import { Grid } from "@nodeme/grid-react";

interface IWizzard {
  show: number;
  icons: any[];
  children: any;
}

const styles = (theme: ITheme) => (definitions: IDefinitions) => ({
  hidden: {
    display: "none",
  },
  active: {
    color: "#fff",
    backgroundColor: theme.palette.get("primary") + " !important",
  },
  badge: {
    width: "40px",
    height: "40px",
    paddingTop: "5px",
    boxSizing: "border-box",
    fontSize: "1.5rem",
    display: "inline-block",
    textAlign: "center",
    borderRadius: "100%",
    backgroundColor: theme.palette.get("default"),
  },
  alignCenter: { textAlign: "center" },
  content: {
    margin: "16px 0px 0px 0px",
    padding: "16px 0px 0px 0px",
    borderTop: "1px solid " + theme.palette.get("default"),
  },
});

const Wizzard: React.FC<IWizzard> = (props) => {
  const classes = buildClasses(styles);
  const itemsCount = props.icons.length;
  return (
    <div>
      <div>
        <Grid spacing={8}>
          {props.icons.map((child, index) => (
            <Grid key={`badge-${index}`} item xs={10 / itemsCount}>
              <div className={classes.alignCenter}>
                <div
                  className={classNames([
                    classes.badge,
                    props.show === index && classes.active,
                  ])}
                >
                  {child}
                </div>
              </div>
            </Grid>
          ))}
        </Grid>
      </div>
      <div className={classes.content}>
        {React.Children.map(props.children, (child, index) => (
          <div
            key={`conent-${index}`}
            className={props.show === index ? classes.show : classes.hidden}
          >
            {child}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Wizzard;
