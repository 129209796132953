import React from "react";
import { ITheme, IDefinitions, buildClasses } from "@nodeme/jss-react";

interface IText {
  children: any;
}

const styles = (theme: ITheme) => (definitions: IDefinitions) => ({
  txt: {
    fontSize: "0.95rem",
    marginTop: "0px",
  },
});

const Block: React.FC<IText> = (props) => {
  const classes = buildClasses(styles, props);
  return <p className={classes.txt}>{props.children}</p>;
};

export default Block;
